<template>
  <div >
    <div class="communication-container" :key="'container_'+hierarchyBasicDetails.id" v-if="storeLoaded">
    <div  class="input-display">
        <div class="left-input apply-text-primary-color">
          {{$t("communication_language_preference")}}
        </div>
          <Button v-if="!communcationsEditing" :label="$t(`edit`)" :type="'outlined'" @click="toggleEditCommunication"/>
          <Button  v-if="communcationsEditing" :label="$t(`save_changes`)" :type="'outlined'" @click="updateCommunication" :disabled="hasError"/>
          <div v-if="communcationsEditing" class="little-left-margin"><Button  :label="$t(`cancel`)" :type="'outlined'" @click="cancelEdit"/></div>
    </div>
    <Checkbox v-if="communicationOptions.length >0" name="communications" :optionsWithKeyValue="communicationOptions" label=""  :isDisabled="!communcationsEditing" v-model="selectedCommunications" :hasError="hasError && communcationsEditing" :errorMessage="$t(`select_at_least_one_option`)" />
    </div>
    <div v-else class="hierarchy-details-container apply-text-primary-color">
      <p>{{$t("select_hierarchy_see_details")}}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Checkbox from '@/app/shared/components/CheckboxGroup.vue'
import Button from '@/app/shared/components/Button.vue'
export default {
  name: 'LoginsManagement',
  data () {
    return {
      communcationsEditing: false,
      selectedCommunications: [],
      hasError: true
    }
  },
  components: {
    Checkbox,
    Button
  },
  async mounted () {
    if (this.selectedHierarchyId !== null) {
      await this.getCommunicationConfigForHierarchy({ params: { hierarchyId: this.hierarchyBasicDetails.id } })
    }
  },
  methods: {
    ...mapActions('HiearachyManagement', ['updateCommunication', 'getCommunicationConfigForHierarchy', 'updateCommunicationForHierarchy']),
    toggleEditCommunication () {
      this.communcationsEditing = !this.communcationsEditing
    },
    cancelEdit () {
      if (this.selectedLanguages !== null) {
        this.selectedCommunications = [...this.selectedLanguages]
      } else {
        this.selectedCommunications = []
      }
      this.communcationsEditing = !this.communcationsEditing
    },
    async updateCommunication () {
      const that = this
      await this.updateCommunicationForHierarchy({
        data: {
          disabledLanguageIds: this.selectedHierarchyCommunicationConfig.availableLanguages.map(language => language.languageId).filter(x => !that.selectedCommunications.includes(x)),
          hierarchyId: this.hierarchyBasicDetails.id,
          languageIds: this.selectedCommunications
        }
      })
      this.communcationsEditing = !this.communcationsEditing
    }
  },
  computed: {
    ...mapState('HiearachyManagement', [
      'hierarchyBasicDetails',
      'selectedHierarchyCommunicationConfig',
      'selectedLanguages',
      'selectedHierarchyId'
    ]),
    storeLoaded () {
      return this.selectedHierarchyCommunicationConfig !== null && this.hierarchyBasicDetails !== undefined && this.selectedHierarchyCommunicationConfig.availableLanguages !== undefined
    },
    communicationOptions () {
      let availableLanguageOptions = []
      if (this.selectedHierarchyCommunicationConfig !== null && this.selectedHierarchyCommunicationConfig.availableLanguages !== undefined && this.selectedHierarchyCommunicationConfig.availableLanguages !== null) {
        availableLanguageOptions = this.selectedHierarchyCommunicationConfig.availableLanguages
          .reduce((cur, language) => { return [...cur, { Key: language.languageId, Value: language.language }] }, [])
      }
      return availableLanguageOptions
    }
  },
  watch: {
    async selectedHierarchyId () {
      this.communcationsEditing = false
      if (this.selectedHierarchyId !== null) {
        await this.getCommunicationConfigForHierarchy({ params: { hierarchyId: this.selectedHierarchyId } })
        if (this.selectedLanguages !== null) {
          if (this.selectedLanguages.length === 0) {
            this.hasError = true
          } else {
            this.hasError = false
          }
        }
      }
    },
    selectedLanguages () {
      if (this.selectedLanguages !== null) {
        this.selectedCommunications = [...this.selectedLanguages]
        if (this.selectedCommunications.length === 0) {
          this.hasError = true
        } else {
          this.hasError = false
        }
      } else {
        this.selectedCommunications = []
        this.hasError = true
      }
    },
    selectedCommunications () {
      if (this.selectedCommunications.length === 0) {
        this.hasError = true
      } else {
        this.hasError = false
      }
    }
  }
}
</script>
<style scoped>
.input-display {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.communication-container {
  padding-left: 4%;
  padding-top: 2%;
  padding-right: 8%
}
.pad-title {
  padding-bottom: 2%;
}
.left-input {
  flex: 1 0 20%;
  padding-right: 0%;
}
.little-left-margin {
  padding-left: 1%
}
.apply-text-primary-color {
  color:var(--text-primary-color);
}
.hierarchy-details-container {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
}
</style>
